// export const contractAddress: string = '0xC1660A490C5B09CC76985a3834A1EeDC447016Ee';
export const JOCKEY_PRICE_IN_FINNEY: string = '100';
export const NFT_IMG_FORMAT: string = 'png';
export const Anchors = {
    welcome: 'welcome',
    utility: 'utility',
    community: 'community',
    art: 'art',
    roadmap: 'roadmap',
    mint: 'mint',
    us: 'us',
    questions: 'questions',
    footer: 'footer',
};

export const DISCORD_URL: string = 'https://discord.gg/vk4UvXkbZV';
export const TWITTER_URL: string = 'https://twitter.com/GenesisJockey';