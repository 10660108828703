import React from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            width: '100%'
        },
        logoContainer: {
            padding:'15px 15px 10px 12px',
            border:'solid 1px',
            borderRadius:'7px',
            width: '80vw',
            maxWidth: '600px',
        },
        logoBackground: {
            backgroundColor: 'rgba(48, 48, 48, 0.9)',
            zIndex: 1,
            padding: '8px 10px',
            borderRadius: '10px'
        }
    })
);
export default function Header() {

    const classes = useStyles();
    return (
        <div className="App-header">
            <img src='/waves/dark-green.svg' alt='waves background'/>
            <img src='/waves/yellow.svg' alt='waves background'/>
            <img src='/waves/dark-blue.svg' alt='waves background'/>
            <img src='/waves/light-blue.svg' alt='waves background'/>
            <div className='header-container'>
                <div className={classes.logoBackground}>
                    <div className={classes.logoContainer + ' yellow'}>
                        <img className={classes.logo} src='/logos/logo-full.png' alt='Genesis Jockey Club logo'/>
                    </div>
                </div>
            </div>
        </div>
    )
}
