import React from 'react';
import {Typography} from "@material-ui/core";
import Footer from "./Footer";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Header from "./Header";
import {Anchors, DISCORD_URL, TWITTER_URL} from "./constants/Constants";
import SectionHeader from "./widgets/SectionHeader";
import FoundersCard from "./widgets/FoundersCard";
import Questions from "./sections/Questions";
import Mint from "./sections/Mint";

interface IProps {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        title: {
            flexGrow: 1,
        },
        alertRoot: {
            fontSize: 'inherit'
        },
        socials: {
            padding: '1em',
            marginTop: '0.5em'
        },
        welcomeSection: {
            display: 'flex',
            minHeight:'400px',
            '@media (max-width:90px)': {
                minHeight:'500px',
            },
            '@media (max-width:750px)': {
                minHeight:'600px',
            },
            '@media (max-width:600px)': {
                minHeight:'500px',
            },
            '@media (max-width:420px)': {
                minHeight:'500px',
            }
        },
        float: {
            float: 'right',
            height: '100%',
            display: 'flex',
            shapeMargin: '0px',
            marginLeft: '16px',
            alignItems: 'flex-end',
            shapeOutside: 'inset(130px 0 0)',
            '@media (max-width:750px)': {
                shapeOutside: 'inset(330px 0 0)',
            },
            '@media (max-width:600px)': {
                shapeOutside: 'inset(270px 0 0)',
            },
            '@media (max-width:420px)': {
                shapeOutside: 'inset(310px 0 0)',
            },
        },
        rightImage: {
            width:'450px',
            marginRight: '-250px',
            transform: 'scaleX(-1);',
            webkitTransform: 'scaleX(-1);',
            '@media (max-width:600px)': {
                width:'350px',
                marginRight: '-190px',
            },
            '@media (max-width:420px)': {
                width:'350px',
                marginRight: '-220px',
            },
        },
        twitterButton: {
            borderRadius: '2em',
            padding: '0.3em',
            backgroundColor: '#1DA1F2',
            fontSize: '2em',
            color: 'white',
        },
        twitterIcon: {
            padding: '0px 2px'
        },
        discordButton: {
            borderRadius: '2em',
            padding: '0.3em',
            backgroundColor: '#5865F2',
            fontSize: '2em',
            color: 'white',
            marginRight: '1em',
            zIndex: 500,
        },
        discordIcon: {
            padding: '0px 4px'
        },
        utilityH3TopPadding: {
            paddingTop: '8px'
        },
        utilityHr: {
            color: 'white',
            backgroundColor: 'white',
            borderWidth: '0px',
            height: '1px',
            width: '90%'
        }
    }),
);

export default function Home(props: IProps) {
    const classes = useStyles();

    return (
        <div className="App">
            <Header/>
            <div id={Anchors.welcome} className='bg-secondary'>
                <div className={classes.welcomeSection}>
                    <div>
                        <div className={classes.float}>
                            <img className={classes.rightImage} src="/Tiger.png" alt='Tiger NFT trait'/>
                        </div>
                        <div className='section'>
                            <Typography variant='h2'>
                                Welcome to the Genesis Jockey Club
                            </Typography>
                            <Typography paragraph={true}>
                                Genesis Jockey Club is a collection of 7,777 unique NFT jockeys. Each jockey will
                                provide access to members only content, including airdrops from the <i>Community
                                Stable</i>. Our mission is to breed horses and get them into the stables of our token
                                holders.
                            </Typography>
                            <Typography variant='h5'>Join the Community</Typography>
                            <div className={classes.socials}>
                                <a target='_blank' rel='noreferrer' className={classes.discordButton} href={DISCORD_URL}>
                                    <i className={classes.discordIcon + ' fab fa-discord'}></i>
                                </a>
                                <a target='_blank' rel='noreferrer' className={classes.twitterButton}
                                   href={TWITTER_URL}>
                                    <i className={classes.twitterIcon + ' fab fa-twitter'}></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-primary'>
                <div className='section'>
                    <Grid container
                          direction="column"
                          spacing={6}>
                        <Grid item>
                            <div id={Anchors.community}>
                                <Grid container
                                      direction="row"
                                      spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant='h2'>
                                            Community
                                        </Typography>
                                        <Typography paragraph={true}>
                                            The Genesis Jockey Club will provide members with an area to discuss all
                                            things NFT, with a focus on ZedRun racing and breeding. Genesis Jockey Club
                                            members will be able to verify they are a token holder in the discord and
                                            access exclusive channels. A sense of community is what holds groups
                                            together, and we aim to create a positive environment where members can be
                                            part of something bigger than themselves. We want to give members access to
                                            something that would not be possible for many people to do on their own.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item>
                            <div id={Anchors.utility}>
                                <Grid container
                                      direction="row"
                                      spacing={3}>
                                    <Grid item xs={12} className='white-border bg-tertiary'>
                                        <Typography align='center' variant='h2' className='h2-center-padding'>
                                            Utility
                                        </Typography>
                                    </Grid>
                                    <Grid container direction="column"
                                          item xs={6} md={4} className='white-border bg-lt-blue'>
                                        <Grid item>
                                            <Typography align='center' color='textSecondary' variant='h3'
                                                        className={classes.utilityH3TopPadding}>
                                                The Sale
                                            </Typography>
                                        </Grid>
                                        <hr className={classes.utilityHr}/>
                                        <Grid item className='utility-top-padding'>
                                            <Typography color='textSecondary' paragraph={true}>
                                                Sales from the initial NFT minting and recurring royalties collected
                                                on Opensea will go towards purchasing and breeding horses
                                                on <b><i>Zed Run</i></b>
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container direction="column"
                                          item xs={6} md={4} className='white-border bg-lt-blue'>
                                        <Grid item>
                                            <Typography color='textSecondary' align='center' variant='h3'
                                                        className={classes.utilityH3TopPadding}>
                                                The Stable
                                            </Typography>
                                        </Grid>
                                        <hr className={classes.utilityHr}/>
                                        <Grid item className='utility-top-padding'>
                                            <Typography color='textSecondary' paragraph={true}>
                                                A self-sufficient stable on <b><i>Zed Run</i></b> will be created and
                                                filled with <b><i>Genesis Nakamoto</i></b> horses. The focus of the
                                                stable will be to breed.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container direction="column"
                                          item xs={12} md={4} className='white-border bg-lt-blue'>
                                        <Grid item>
                                            <Typography color='textSecondary' align='center' variant='h3'
                                                        className={classes.utilityH3TopPadding}>
                                                The Purpose
                                            </Typography>
                                        </Grid>
                                        <hr className={classes.utilityHr}/>
                                        <Grid item className='utility-top-padding'>
                                            <Typography color='textSecondary' paragraph={true}>
                                                Select offspring will be airdropped to Genesis Jockey Club members on a
                                                continual basis. The size of the stable will dictate how many members
                                                receive an offspring during each airdrop.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className='white-border bg-tertiary'>
                                        <Typography align='center' variant='h3' className='h3-center-padding'>
                                            The long-term vision of the Genesis Jockey Club is for every member to
                                            periodically receive airdropped Nakamoto offspring.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item>
                            <div id={Anchors.art}>
                                <Grid container
                                      direction="row"
                                      spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant='h2'>
                                            Art
                                        </Typography>
                                        <Typography paragraph={true}>
                                            We want to be different at Genesis Jockey Club. We truly want to make this a
                                            unique experience for you in the metaverse, which is why we chose to do
                                            something totally different and create images with polygon art. From our
                                            artwork to the utility and the community, everything was designed to have
                                            the best interest of the member in mind.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className='bounding-box'>
                    <img className='waves' src='waves/light-blue.svg' alt='light blue waves background'/>
                </div>
            </div>
            <div id={Anchors.mint} className='bg-tertiary'>
                <Mint/>
            </div>
            <div id={Anchors.roadmap}>
                <Grid container
                      direction="row"
                      spacing={3}>
                    <Grid item xs={12}>
                        <img className='roadmap' src='/Roadmap.svg' alt='Genesis Jockey Club roadmap'/>
                    </Grid>
                </Grid>
            </div>
            <div id={Anchors.questions} className='section bg-secondary'>
                <Questions/>
            </div>
            <div id={Anchors.us}>
                <SectionHeader title='Who we are'/>
                <div className='section'>
                    <Grid container
                          justifyContent='center'
                          direction="row"
                          spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <FoundersCard name='bcam' twitter='bcamjockey' bio={
                                <>
                                    The creative. Bcam is our crypto expert. Miner, collector, and now creator. For
                                    coming up with this idea, Bcam's reward was being selected as our esteemed artist.
                                    All art work was made using only colored pencils.
                                </>
                            }/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FoundersCard name='mackle' twitter='Mackle_Jockey' bio={
                                <>
                                    The engineer. A backend developer at heart, Mackle somehow Google'd his way through
                                    creating this website and our ERC-721 smart contract. If you find any bugs, it's
                                    because Bcam somehow got hold of the code base.
                                </>
                            }/>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
