import Web3 from "web3";
import {AbiItem, toBN} from "web3-utils";
import {JOCKEY_PRICE_IN_FINNEY} from "../constants/Constants";
import {AppConfig} from "../constants/AppConfig";

const GenesisJockeysABI = require("../resources/GenesisJockey.json");

export interface GenesisJockeyContract {
    isSaleOpen: Function;
    totalSupply: Function;
    maximumSupply: Function;
    mintJockeys: Function;
}

export interface MintJockeysResponse {
    tokenIds: string[]
}

export function GenesisJockeyContractClient(web3: Web3): GenesisJockeyContract {

    const contract = new web3.eth.Contract(GenesisJockeysABI.abi as AbiItem[], AppConfig.contractAddress);

    const isSaleOpen = async (): Promise<boolean> => {
        try {
            console.log("Calling isSaleOpen()")
            const _isSaleOpen = await contract.methods.isSaleOpen().call();
            console.log("isSaleOpen", _isSaleOpen);
            return _isSaleOpen;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const totalSupply = async (address: string): Promise<number> => {
        try {
            console.log("Calling totalSupply()")
            const _totalSupply = await contract.methods.totalSupply().call({from: address})
            console.log("totalSupply", _totalSupply);
            return _totalSupply;
        } catch (e) {
            console.error(e);
            return -1;
        }
    }

    const maximumSupply = async (address: string): Promise<number> => {
        try {
            console.log("Calling maximumSupply()")
            const _maximumSupply = await contract.methods.maximumSupply().call({from: address});
            console.log("maximumSupply", _maximumSupply);
            return _maximumSupply;
        } catch (e) {
            console.error(e);
            return -1;
        }
    }

    function parseTransfers(transfer: any) {
        if (Array.isArray(transfer)) {
            return transfer
        } else {
            return [transfer];
        }
    }

    function parseMintJockeysResponse(response: any): MintJockeysResponse {
        const transfers: any[] = parseTransfers(response.events.Transfer);
        const tokenIds: string[] = [];
        for (const transfer of transfers) {
            const tokenId: string = transfer.returnValues.tokenId;
            tokenIds.push(tokenId);
        }
        return {tokenIds};

    }

    const mintJockeys = async (address: string, numJockeys: number): Promise<MintJockeysResponse> => {

        const amountPerJockey = web3.utils.toWei(toBN(JOCKEY_PRICE_IN_FINNEY), 'finney');
        const amount = amountPerJockey.mul(toBN(numJockeys!));
        console.log("Calling mintJockeys()")
        const response = await contract.methods
            .mintJockeys(numJockeys)  //function in contract
            .send({
                from: address,
                value: amount,
                gasPrice: '20000000000',
                gasLimit: '200000'
            });
        console.log("Ethereum response", response);
        const mintJockeysResponse: MintJockeysResponse = parseMintJockeysResponse(response);
        return mintJockeysResponse;

        // const tokenIds: string[] = [];
        // for(let i = 1; i <= numJockeys; i++) {
        //     tokenIds.push(i.toString());
        // }
        // return {tokenIds}
    }

    return {
        isSaleOpen,
        totalSupply,
        maximumSupply,
        mintJockeys
    }
}