import React, {ReactElement} from 'react';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {Anchors, DISCORD_URL, TWITTER_URL} from "./constants/Constants";
import TermsDialog from "./Terms";
import {Typography} from "@material-ui/core";
import {AppConfig} from "./constants/AppConfig";

export default function Footer() {

    const links: ReactElement[] = [
        {
            href: `#${Anchors.welcome}`,
            name: 'Welcome'
        },
        {
            href: `#${Anchors.utility}`,
            name: 'Utility'
        },
        {
            href: `#${Anchors.community}`,
            name: 'Community'
        },
        {
            href: `#${Anchors.art}`,
            name: 'Art'
        },
        {
            href: `#${Anchors.mint}`,
            name: 'Mint Jockeys'
        },
        {
            href: `#${Anchors.roadmap}`,
            name: 'Roadmap'
        },
        {
            href: `#${Anchors.us}`,
            name: 'Who we are'
        },
        {
            href: `#${Anchors.questions}`,
            name: 'Questions'
        }
    ].map((link, index:number) => {
        return <Grid key={index} item xs={6}>
            <Link href={link.href}>
                {link.name}
            </Link>
        </Grid>
    });
    let siteNav =
        <Grid container
              justifyContent="center"
              alignItems="center"
              spacing={2}>
            {links}
            {AppConfig.allowMinting && <Grid item xs={12}>
                <TermsDialog/>
            </Grid>}
            <Grid item xs={12}>
                <div className='copyright'>
                    Genesis Jockey Club, LLC &copy; 2021
                </div>
            </Grid>
        </Grid>;

    const contract = AppConfig.allowMinting ? <Grid item xs={12}>
        <div className='contract-address'>
            <Link href={AppConfig.etherscanUrl}>View our Contract</Link>
        </div>
    </Grid> : undefined;
    return (
        <footer id={Anchors.footer}>
            <Typography variant='subtitle1'>
                <Grid container spacing={3}>
                    {contract}
                    <Grid item xs={12}>
                        {siteNav}
                    </Grid>
                    <Grid className='socials' item xs={12}>
                        <a target='_blank' rel='noreferrer' className='discord' href={DISCORD_URL}>
                            <i className='fab fa-discord'></i>
                        </a>
                        <a target='_blank' rel='noreferrer' className='twitter' href={TWITTER_URL}>
                            <i className="fab fa-twitter"></i>
                        </a>
                    </Grid>
                </Grid>
            </Typography>
        </footer>
    )
}
