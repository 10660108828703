export interface IAppConfig {
    contractAddress: string;
    etherscanUrl: string;
    imageUrlPrefix: string;
    allowMinting: boolean;
}

const devoContractAddress: string = '0xD229e3182294Ca134cd754742b8C7Df3b3d2238D';
const AppConfigs: Record<string, IAppConfig> = {
    Devo: {
        contractAddress: devoContractAddress,
        etherscanUrl: `https://rinkeby.etherscan.io/address/${devoContractAddress}`,
        imageUrlPrefix: 'https://cdn.devo.genesisjockey.club/images/nft/',
        allowMinting: false
    }
}

// Change to Prod when ready
export const AppConfig = AppConfigs.Devo