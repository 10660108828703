import Grid from "@material-ui/core/Grid";
import {createStyles, Link, Theme, Typography} from "@material-ui/core";
import React, {ReactElement} from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {DISCORD_URL, TWITTER_URL} from "../constants/Constants";

interface QandA {
    question: string,
    answer: string | ReactElement
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: '#46cd6e',
            color: 'white'
        },
        whiteIcon: {
            color: 'white'
        }
    }),
);


export default function Questions() {
    const classes = useStyles();
    const questions: QandA[] = [{
        question: 'Are you affiliated with ZedRun?',
        answer: 'No. We are an independent community, but all horses purchased and given away will be for use on ZedRun.'
    }, {
        question: 'How can I join?',
        answer: 'Membership is based on ownership of an NFT. The NFT gives you access to the community.'
    }, {
        question: 'When will the sale begin?',
        answer: <>Our plan is to open up minting in October.
            Follow our <Link target='_blank' rel='noreferrer' color='textSecondary'
                             href={TWITTER_URL}>
                Twitter</Link> and <Link target='_blank' rel='noreferrer'
                                         color='textSecondary' href={DISCORD_URL}>Discord</Link> for the
            latest updates. We will update the website with more information as we have it.</>
    }, {
        question: 'How much do they cost?',
        answer: "Each Genesis Jockey NFT costs 0.1ETH."
    }, {
        question: 'When will my NFT be revealed?',
        answer: "NFTs will be revealed immediately after minting."
    }, {
        question: 'Where can I get my other questions answered?',
        answer: <>Head on over to our <Link target='_blank' rel='noreferrer' color='textSecondary'
                                            href={DISCORD_URL}>Discord</Link> to connect with us or find us on Twitter</>
    }];

    const questionAccordians = questions.map((q, index) => {
        return (
            <Grid key={index} item xs={12} sm={6}>
                <Accordion className={classes.root}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.whiteIcon}/>}
                        aria-controls={`question${index}-content`}
                        id={`question${index}-header`}
                    >
                        <Typography variant={'h6'}>{q.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {q.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        );
    })
    return (
        <Grid container
              justifyContent='center'
              direction="row"
              spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h2'>
                    Questions?
                </Typography>
            </Grid>
            {questionAccordians}
        </Grid>
    );
}