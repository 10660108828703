import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Link} from "@material-ui/core";
import {Anchors} from "./constants/Constants";

export default function TermsDialog() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Link href={`#${Anchors.footer}`} onClick={handleClickOpen}>Terms</Link>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll='paper'
                maxWidth='md'
                aria-labelledby="terms-dialog-title"
                aria-describedby="terms-dialog-description"
                className='terms'
            >
                <DialogTitle id="terms-dialog-title">Terms and Conditions</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="terms-dialog-description"
                        tabIndex={-1}
                    >
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Mauris pharetra et ultrices neque ornare aenean euismod
                            elementum. Nibh sed pulvinar proin gravida. Ultricies lacus sed turpis tincidunt id aliquet
                            risus feugiat in. Consequat id porta nibh venenatis. Etiam non quam lacus suspendisse.
                            Senectus et netus et malesuada fames ac turpis. Cursus risus at ultrices mi. Odio euismod
                            lacinia at quis risus sed vulputate odio ut. Morbi tristique senectus et netus et malesuada
                            fames ac turpis. Amet nisl suscipit adipiscing bibendum est ultricies integer quis.
                        </p>
                        <p>
                            Habitasse platea dictumst quisque sagittis purus. Morbi leo urna molestie at. Et ligula
                            ullamcorper malesuada proin libero nunc consequat interdum varius. Pretium quam vulputate
                            dignissim suspendisse in est ante. Ut lectus arcu bibendum at varius. Dui nunc mattis enim
                            ut. Nulla facilisi cras fermentum odio eu feugiat pretium nibh. Imperdiet dui accumsan sit
                            amet nulla facilisi morbi. Sollicitudin tempor id eu nisl nunc mi. Integer vitae justo eget
                            magna fermentum iaculis. Integer vitae justo eget magna fermentum iaculis eu. Est lorem
                            ipsum dolor sit amet consectetur adipiscing elit pellentesque. Ultrices mi tempus imperdiet
                            nulla malesuada pellentesque elit eget gravida. Mi ipsum faucibus vitae aliquet nec
                            ullamcorper sit. Quam id leo in vitae turpis massa sed elementum tempus. In pellentesque
                            massa placerat duis ultricies lacus sed turpis.
                        </p>
                        <p>
                            Euismod elementum nisi quis eleifend quam adipiscing vitae. Nisi vitae suscipit tellus
                            mauris a. Consequat semper viverra nam libero justo laoreet sit amet cursus. Odio aenean sed
                            adipiscing diam donec adipiscing tristique risus. Ut diam quam nulla porttitor massa id
                            neque. Cursus metus aliquam eleifend mi in nulla. Enim nunc faucibus a pellentesque sit.
                            Velit egestas dui id ornare arcu odio. Adipiscing elit ut aliquam purus. Egestas sed sed
                            risus pretium. Porta lorem mollis aliquam ut porttitor leo a. Lectus quam id leo in vitae
                            turpis. Elit sed vulputate mi sit. Metus dictum at tempor commodo ullamcorper a lacus
                            vestibulum sed. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Est
                            sit amet facilisis magna etiam tempor. Eu ultrices vitae auctor eu augue ut. Amet
                            consectetur adipiscing elit ut aliquam purus sit. Mi tempus imperdiet nulla malesuada
                            pellentesque elit eget gravida. Nisl vel pretium lectus quam id leo in vitae.
                        </p>
                        <p>
                            Augue ut lectus arcu bibendum at varius vel. Consequat mauris nunc congue nisi vitae
                            suscipit tellus. A diam maecenas sed enim ut sem viverra. Dui nunc mattis enim ut tellus
                            elementum sagittis vitae et. Non diam phasellus vestibulum lorem. Commodo odio aenean sed
                            adipiscing diam. Et magnis dis parturient montes. Lacus suspendisse faucibus interdum
                            posuere lorem. Lorem mollis aliquam ut porttitor. Adipiscing bibendum est ultricies integer
                            quis auctor. Scelerisque eleifend donec pretium vulputate sapien nec. Nisi lacus sed viverra
                            tellus in. Lobortis scelerisque fermentum dui faucibus in ornare quam. Tellus at urna
                            condimentum mattis pellentesque. Ultricies integer quis auctor elit sed. Euismod elementum
                            nisi quis eleifend quam adipiscing vitae proin sagittis.
                        </p>
                        <p>
                            Pretium vulputate sapien nec sagittis aliquam malesuada bibendum arcu vitae. Eget est lorem
                            ipsum dolor sit. Leo in vitae turpis massa sed. Tempus egestas sed sed risus. Habitant morbi
                            tristique senectus et netus. Eu augue ut lectus arcu bibendum at varius vel pharetra.
                            Consequat semper viverra nam libero justo laoreet sit amet cursus. Non curabitur gravida
                            arcu ac tortor dignissim convallis. Purus faucibus ornare suspendisse sed nisi lacus sed
                            viverra. Dui nunc mattis enim ut tellus elementum sagittis vitae et. In iaculis nunc sed
                            augue lacus viverra vitae congue. Viverra ipsum nunc aliquet bibendum enim facilisis.
                            Tincidunt arcu non sodales neque sodales ut etiam.
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
