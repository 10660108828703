import React, {ReactElement} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {CardHeader} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles({
    root: {
        maxWidth: '420px',
        margin: 'auto',
        backgroundColor:'#333',
        border:'1px #ccc solid',
        color:'#ccc'
    },
    media: {
        maxHeight: '420px',
        maxWidth: '420px',
        width: '100%',
        height: '100%'
    },
    twitterIcon: {
        color:'#1DA1F2'
    },
    discordIcon: {
        color:'#5865F2'
    },
});

interface IProps {
    name: string;
    bio: ReactElement;
    twitter: string;
}

export default function FoundersCard(props: IProps) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader
                title={props.name}
                action={
                <>
                    <IconButton href={`https://twitter.com/${props.twitter}`} target='_blank' rel='noreferrer'
                                aria-label="follow">
                        <i className={classes.twitterIcon + ' fab fa-twitter'}/>
                    </IconButton>
                </>}
            />
            <img className={classes.media} src={`${props.name}.png`} alt={`${props.name}'s profile picture`} />
            <CardContent>
                <Typography paragraph>
                    {props.bio}
                </Typography>
            </CardContent>
        </Card>
    );
}