import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {AppConfig} from "./constants/AppConfig";
import {NFT_IMG_FORMAT} from "./constants/Constants";

const useStyles = makeStyles({
    root: {
        maxWidth:'420px',
        margin: 'auto'
    },
    media: {
        maxHeight:'420px',
        maxWidth:'420px',
        width: '100%',
        height:'100%'
    },
});

interface IProps {
    tokenId: string;
}

export default function NFTCard(props: IProps) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <img src={`${AppConfig.imageUrlPrefix}${props.tokenId}.${NFT_IMG_FORMAT}`} className={classes.media} alt="jockey"/>
            <CardContent>
                <Typography gutterBottom variant="h6">
                    {`Genesis Jockey #${props.tokenId}`}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary">
                    View on Opensea
                </Button>
            </CardActions>
        </Card>
    );
}