import React from 'react';
import Web3 from "web3";
import {Button} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Grid from "@material-ui/core/Grid";

declare let window: any;

interface IProps {
    walletConnectedCallback: Function
}

interface IState {
    showUnsupportedBrowser: boolean;
    showMetaMaskConnectionError: boolean;
}

export default class ConnectToWallet extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {showUnsupportedBrowser: false, showMetaMaskConnectionError: false}
        this.connectWallet = this.connectWallet.bind(this);
        this.close = this.close.bind(this);
    }

    handleChainChanged() {
        // We recommend reloading the page, unless you must do otherwise
        this.refresh();
    }

    private refresh() {
        window.location.reload();
    }

    private async connectWallet() {
        if (window.ethereum) {
            console.log("Connecting to Metamask");
            try {
                const web3Provider: any = window.ethereum;
                let web3 = new Web3(web3Provider);
                let chainId: number = await web3.eth.getChainId();

                const accounts = await web3Provider.request({method: 'eth_requestAccounts'});
                // const chainId = await web3Provider.request({method: 'eth_chainId'});
                console.log('account:', accounts[0], 'chain:', chainId)

                window.ethereum.on('chainChanged', this.handleChainChanged);

                this.props.walletConnectedCallback(true, accounts[0], web3);
            } catch (err) {
                if (err.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    // If this happens, the user rejected the connection request.
                    console.log('Please connect to MetaMask.');
                } else if (err.code === -32002) {
                    this.setState({
                        showMetaMaskConnectionError: true
                    });
                } else {
                    console.error(err);
                }
            }
        } else {
            console.log("No ethereum wallet plugin detected");
            this.setState({
                showUnsupportedBrowser: true
            })
            // web3Provider = new Web3.providers.HttpProvider('http://localhost:7545');
        }
    }

    gotoMetamask() {
        window.open('https://metamask.io', "_blank");
    }

    private getUnsupportedBrowserDialog() {
        return <Dialog
            open={this.state.showUnsupportedBrowser}
            onClose={this.close}
            aria-labelledby="ethereum-plugin-modal"
            aria-describedby="ethereum-plugin-modal">
            <DialogTitle id="form-dialog-title">Non-ethereum browser detected</DialogTitle>
            <DialogContent>
                <DialogContentText>Genesis Jockey requires a supported browser to connect your ethereum wallet.
                    MetaMask is our preferred plugin for connecting to the ethereum network</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.close} color="default">
                    Close
                </Button>
                <Button variant='contained' onClick={this.gotoMetamask} color="primary">
                    Goto MetaMask
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private getMetamaskClosedDialog() {
        return <Dialog
            open={this.state.showMetaMaskConnectionError}
            onClose={this.close}
            aria-labelledby="ethereum-plugin-modal"
            aria-describedby="ethereum-plugin-modal">
            <DialogTitle id="form-dialog-title">Failed to connect to Metamask</DialogTitle>
            <DialogContent>
                <DialogContentText>Looks like there was an error connecting to your MetaMask wallet. Please refresh
                    the page and try again.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.close} color="primary">
                    Close
                </Button>
                <Button variant='contained' onClick={this.refresh} color="primary">
                    Refresh
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private close() {
        this.setState({showUnsupportedBrowser: false, showMetaMaskConnectionError: false});
    }

    render() {
        const metamaskClosedDialog = this.getMetamaskClosedDialog();
        const unsupportedBrowserDialog = this.getUnsupportedBrowserDialog();
        return (
            <Grid item>
                {metamaskClosedDialog}
                {unsupportedBrowserDialog}

                <div className='button-spinner-wrapper'>
                    <Button startIcon={<AccountBalanceWalletIcon/>} size='large'
                            variant='contained'
                            color='primary'
                            onClick={this.connectWallet}>
                        Connect Wallet
                    </Button>
                </div>
            </Grid>
        )
    }
}
