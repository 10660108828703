import React from 'react';

import Button from '@material-ui/core/Button';
import MintJockeysModal from "./MintJockeysModal";
import Web3 from "web3";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {GenesisJockeyContract} from "./client/GenesisJockeyContractClient";

const GenesisJockeysABI = require("./resources/GenesisJockey.json");

interface IProps {
    genesisJockeyContract: GenesisJockeyContract;
    web3Client: Web3;
    address: string;
}

interface IState {
    isModalOpen: boolean;
    totalSupply: number;
    maxSupply: number;
}

export default class MintJockeys extends React.Component<IProps, IState> {

    readonly contract: GenesisJockeyContract;

    componentDidMount() {
        this.refreshTotalSupply();
    }

    private async refreshTotalSupply() {
        const totalSupply: number = await this.contract.totalSupply(this.props.address);
        const maxSupply: number = await this.contract.maximumSupply(this.props.address);
        this.updateTotalSupply(totalSupply, maxSupply);
    }

    constructor(props: IProps) {
        super(props);

        this.contract = props.genesisJockeyContract;
        this.state = {
            isModalOpen: false,
            totalSupply: 0,
            maxSupply: 0
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.updateTotalSupply = this.updateTotalSupply.bind(this);
        this.refreshTotalSupply = this.refreshTotalSupply.bind(this);
    }

    private updateTotalSupply(totalSupply: number, maxSupply: number) {
        this.setState({
            totalSupply,
            maxSupply
        });
    }

    private closeModal() {
        this.setState({
            isModalOpen: false
        })
    }

    private openModal() {
        this.setState({
            isModalOpen: true
        })
    }

    render() {
        let button = <Button size='large' fullWidth={true} variant='contained' onClick={this.openModal}>Mint
            Jockeys</Button>
        let remaining = <Grid item>
            <Typography>
                {this.state.maxSupply - this.state.totalSupply} of {this.state.maxSupply} Jockeys remain
            </Typography>
        </Grid>;
        return (
            <>
                <Grid item>
                    {button}
                </Grid>
                {this.state.totalSupply !== 0 && remaining}
                <MintJockeysModal web3Client={this.props.web3Client} address={this.props.address}
                                  contract={this.contract} isModalOpen={this.state.isModalOpen}
                                  handleClose={this.closeModal}
                                  refreshTotalSupply={this.refreshTotalSupply}/>
            </>
        );
    }
}
