import React, {ReactElement, useState} from 'react';
import ConnectToWallet from '../ConnectToWallet';
import MintJockeys from "../MintJockeys";
import Web3 from "web3";
import {Link, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {GenesisJockeyContract, GenesisJockeyContractClient} from "../client/GenesisJockeyContractClient";
import {Alert} from "@material-ui/lab";
import {AppConfig} from "../constants/AppConfig";
import {DISCORD_URL} from "../constants/Constants";

interface IProps {
}

interface IState {
    isModalOpen: boolean;
    isWalletConnected: boolean;
    address: string;
    web3Client?: Web3;
    isSaleOpen: boolean;
    client?: GenesisJockeyContract
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        title: {
            flexGrow: 1,
        },
        alertRoot: {
            fontSize: 'inherit'
        },
        socials: {
            padding: '1em',
            marginTop: '0.5em',
            marginBottom: '30px'
        },
        mintSection: {
            display: 'flex',
        },
        float: {
            float: 'left',
            height: '100%',
            display: 'flex',
            shapeMargin: '0px',
            marginRight:'16px',
            alignItems: 'flex-end',
            shapeOutside: 'inset(130px 0 0)',
            '@media (max-width:600px)': {
                shapeOutside: 'inset(100px 0 0)',
            },
        },
        leftImage: {
            width:'350px',
            marginLeft: '-180px',
            '@media (max-width:600px)': {
                width:'250px',
                marginLeft: '-150px',
            },
        },
        twitterButton: {
            borderRadius: '1em',
            padding: '12px',
            backgroundColor: '#1DA1F2',
            fontSize: '2em',
            color: 'white',
        },
        discordButton: {
            borderRadius: '5em',
            padding: '12px',
            backgroundColor: '#5865F2',
            fontSize: '2em',
            color: 'white',
            marginRight: '1em',
            zIndex: 500,
        },
        discordIcon: {
            padding: '0px 2px'
        }
    }),
);

export default function Mint(props: IProps) {
    const classes = useStyles();

    const [state, setState] = useState<IState>({
        isModalOpen: false,
        isWalletConnected: false,
        address: '',
        isSaleOpen: false
    });

    const walletCallback = async (isWalletConnected: boolean, address: string, web3Client: Web3) => {
        console.log("callback in wallet");

        let client: GenesisJockeyContract | undefined = undefined;
        let isSaleOpen: boolean = false;
        if (isWalletConnected) {
            client = GenesisJockeyContractClient(web3Client);
            isSaleOpen = await client.isSaleOpen()
        }
        setState({
                ...state,
                isWalletConnected,
                address,
                web3Client,
                isSaleOpen,
                client
            }
        )
    }
    let mintJockeysContent: ReactElement;
    console.log("Loading Button");
    const containerize = (reactElement: ReactElement): ReactElement => {
        return reactElement
    };
    if (state.isWalletConnected) {
        if (state.isSaleOpen) {
            mintJockeysContent = containerize(<MintJockeys address={state.address} genesisJockeyContract={state.client!}
                                                           web3Client={state.web3Client!}/>);
        } else {
            mintJockeysContent = containerize(
                <Typography paragraph={true}>
                    <Alert className={classes.alertRoot} severity='warning'>
                        Our sale is not yet open. Please check back soon. We'll be opening shortly!
                    </Alert>
                </Typography>);
        }
    } else {
        mintJockeysContent = containerize(
            <ConnectToWallet walletConnectedCallback={walletCallback}/>
        );
    }
    const section = AppConfig.allowMinting ?
        <>
            <Typography paragraph={true}>
                This is where the magic happens. Don't wait. Mint now and gain access to the
                exclusive community before they're all gone.
            </Typography>
            <Typography paragraph={true}>
                Each Genesis Jockey costs 0.1ETH. You can mint up to 7 Jockeys per transaction.
            </Typography>
            {mintJockeysContent}
        </> :
        <Typography paragraph={true}>
            Our NFTs are not quite ready for sale yet. Once launched, each Genesis Jockey will be available
            for 0.1ETH. Connect with us on <Link target='_blank' href={DISCORD_URL}>Discord</Link> for the latest information.
        </Typography>
    return (
        <div className={classes.mintSection}>
            <div>
                <div className={classes.float}>
                    <img className={classes.leftImage} src="/Chicken.png" alt='Chicken NFT trait'/>
                </div>
                <div className='section'>
                    <Typography variant='h2'>
                        Mint Jockeys
                    </Typography>
                    <div>
                            {section}
                    </div>
                </div>
            </div>
        </div>
    );
};
