import React from 'react';
import Home from "./Home";
import './App.css';
import 'animate.css/animate.css'
import { createTheme, MuiThemeProvider} from "@material-ui/core";

const theme = createTheme({
    palette: {
        primary: {
            main:'#ffbd4a'
        },
        secondary: {
            main: '#333333'
        }
    },
    typography: {
        fontFamily: 'Bicubik',
        h1: {
            fontSize: '3.5em',
            letterSpacing: '6px',
            '@media (max-width:1200px)': {
                fontSize: '2.5em',
            },
            '@media (max-width:900px)': {
                fontSize: '1.6em',
            },
            '@media (max-width:520px)': {
                fontSize: '1em',
                letterSpacing: '4px'
            },
        },
        h2: {
            fontSize: '3.0em',
            paddingBottom: '20px',
            letterSpacing: '6px',
            '@media (max-width:1200px)': {
                fontSize: '2.5em',
            },
            '@media (max-width:600px)': {
                fontSize: '1.7em',
                letterSpacing: '4px'
            },
        },
        h3: {
            fontSize: '1.8em',
            paddingBottom: '20px',
            letterSpacing: '6px',
            '@media (max-width:1200px)': {
                fontSize: '1.6em',
            },
            '@media (max-width:600px)': {
                fontSize: '1em',
                letterSpacing: '3px'
            },
        },
        h4: {
            fontSize: '3.0em',
            letterSpacing: '6px',
            '@media (max-width:1200px)': {
                fontSize: '2.5em',
            },
            '@media (max-width:600px)': {
                fontSize: '1.7em',
                letterSpacing: '4px'
            },
        },
        h5: {
            letterSpacing: '4px',
            '@media (max-width:600px)': {
                fontSize: '1.2em',
            },
        },
        h6: {
            fontSize: '1.3em',
            letterSpacing: '4px',
            '@media (max-width:600px)': {
                fontSize: '1.0em',
                letterSpacing: '2px'
            },
        },
        body1: {
            'font-family': 'HKGrotesk-Light',
            'font-size': '1.5em',
            '@media (max-width:600px)': {
                fontSize: '1.1em',
            },
        },
        body2: {
            'font-family': 'HKGrotesk-Light',
            'font-size': '1.5em',
        },
        subtitle1: {
            'font-family': 'HKGrotesk-Light',
            'font-size': '1.2em',
            '@media (max-width:600px)': {
                fontSize: '1.1em',
            },
        }
    }
});
export default function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <Home/>
        </MuiThemeProvider>
    )
}
