import React, {useLayoutEffect, useState} from 'react';
import {TextField, Theme} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Web3 from "web3";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import sample from "./resources/sample-2.png";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import {Alert} from "@material-ui/lab";
import {Color} from "@material-ui/lab/Alert/Alert";
import {GenesisJockeyContract, MintJockeysResponse} from "./client/GenesisJockeyContractClient";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NFTCard from "./NFTCard";

interface IProps {
    isModalOpen: boolean;
    handleClose: Function;
    web3Client: Web3;
    contract: GenesisJockeyContract;
    address: string;
    refreshTotalSupply: Function;
}

interface Error {
    message: string
    severity: Color
}

enum ModalState {
    MintForm,
    ShowResults
}

interface IMintFormState {
    numJockeys?: number;
    disableButtons: boolean;
    isLoading: boolean;
    totalSupply: number;
    maxSupply: number;
    formError: boolean;
    hasErrorMessage: boolean;
    error?: Error;
}

interface IShowResultsState {
    tokenIds: string[]
}


const useStyles = makeStyles((theme: Theme) => ({
    dialog: {
        backgroundColor: '#eee',
        paddingBottom: '15px'
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    mintJockeyGif: {
        maxHeight: '320px',
        maxWidth: '320px',
        height: '100%',
        width: '100%',
        pointerEvents: 'none'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    mintFormWidth: {
        maxWidth: '650px'
    },
    showResultsWidth: {
        maxWidth: '840px'
    }
}));


export default function MintJockeysModal(props: IProps) {
    const classes = useStyles();

    const [modalState, setModalState] = useState<ModalState>(ModalState.MintForm);
    const [mintFormState, setMintFormState] = useState<IMintFormState>({
        disableButtons: false, totalSupply: 0, maxSupply: 0, formError: false, isLoading: false,
        hasErrorMessage: false
    });
    const [showResultsState, setShowResultsState] = useState<IShowResultsState>();

    const updateMintFormState = (s: any) => {
        setMintFormState({
            ...mintFormState,
            ...s
        });
    }

    const refresh = async () => {
        console.log("Update max supply and total supply");

        const totalSupply: number = await props.contract.totalSupply(props.address);
        const maxSupply: number = await props.contract.maximumSupply(props.address);
        updateMintFormState({
            totalSupply,
            maxSupply
        });
    }
    useLayoutEffect(() => {
        refresh();
    }, []);

    const handleClose = () => {
        props.handleClose();
    }

    const mintAgain = () => {
        updateMintFormState({numJockeys: undefined})
        setModalState(ModalState.MintForm);
    }

    const receiptHandler = (response: MintJockeysResponse) => {
        updateMintFormState({
            isLoading: false
        })
        setShowResultsState({tokenIds: response.tokenIds});
        setModalState(ModalState.ShowResults);
    }

    const handleSubmit = async (event: any) => {

        let numJockeys = mintFormState.numJockeys!;
        console.log("Submit numJockeys", numJockeys);
        if (numJockeys === undefined || numJockeys > 7 || numJockeys < 1) {
            console.error("Invalid number of Jockeys input", numJockeys);
            updateMintFormState({
                formError: true
            })
            return;
        }
        updateMintFormState({
            formError: false,
            isLoading: true,
            hasErrorMessage: false,
        });
        console.log(props.contract)
        try {
            // TODO: Add loading message
            let response: MintJockeysResponse = await props.contract.mintJockeys(props.address, numJockeys);
            console.log("response: ", response);
            refresh();
            props.refreshTotalSupply();

            // TODO: Add success message
            // TODO: Show user their newly minted items
            receiptHandler(response);
        } catch (e) {
            if (e.code === 4001) {
                updateMintFormState({
                    hasErrorMessage: true,
                    error: {
                        severity: 'error',
                        message: e.message
                    },
                    isLoading: false
                });
            }
            console.error('exception', e);
        }
    }

    const changeNumberOfJockeys = (e: any) => {
        let numJockeys = e.target.value;
        console.log(e);
        updateMintFormState({
            numJockeys,
            formError: numJockeys !== null && (numJockeys > 7 || numJockeys < 1)
        })
    }

    function getDialogContent() {
        if (modalState === ModalState.MintForm) {
            return <>
                <DialogTitle id="form-dialog-title">Mint Jockeys
                    <IconButton color="inherit" className={classes.closeButton} onClick={handleClose}
                                aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction='row' spacing={3}>
                        <Grid item xs={12}>
                            <DialogContentText>
                                You can mint 1-7 Jockeys per transaction
                            </DialogContentText>
                        </Grid>
                        <Grid container direction='column' alignItems='center' item xs={12}>
                            <Grid item>
                                <img src={sample} className={classes.mintJockeyGif} alt="jockey"/>
                            </Grid>
                            <Grid item>
                                <DialogContentText>
                                    {mintFormState.maxSupply - mintFormState.totalSupply} of {mintFormState.maxSupply} Jockeys
                                    remaining
                                </DialogContentText>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="numJockeys"
                                label="Number of Jockeys"
                                onChange={changeNumberOfJockeys}
                                required={true}
                                focused={true}
                                error={mintFormState.formError}
                                helperText={mintFormState.formError ? 'Enter a number between 1-7' : ''}
                                type='number'
                                variant="outlined"
                                fullWidth={true}
                                color='secondary'
                            />
                            {mintFormState.hasErrorMessage &&
                            <Alert severity={mintFormState.error!.severity}>{mintFormState.error!.message}</Alert>}
                        </Grid>
                        <Grid item xs={12}>
                            <div className='button-spinner-wrapper'>
                                <Button variant='contained'
                                        disabled={mintFormState.isLoading || mintFormState.disableButtons}
                                        onClick={handleSubmit} color="primary"
                                        size='large'
                                        fullWidth={true}>
                                    Mint
                                </Button>
                                {mintFormState.isLoading && <CircularProgress size={24} className='loading-spinner'/>}
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </>;
        } else {
            const count = showResultsState?.tokenIds!.length;
            const nftCards = showResultsState?.tokenIds!.map(tokenId =>
                <Grid key={tokenId} item xs={12} md={count === 1 ? 12 : 6}>
                    <NFTCard tokenId={tokenId}/>
                </Grid>
            );
            return <>
                <DialogTitle id="form-dialog-title">
                    Congratulations!
                    <IconButton color="inherit" className={classes.closeButton} onClick={handleClose}
                                aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container alignContent='center' alignItems='center' spacing={2}>
                        <Grid item xs={12}>
                            <DialogContentText color={'inherit'}>
                                You've successfully minted {count} Jockeys.
                            </DialogContentText>
                        </Grid>
                        <Grid container spacing={2} item xs={12}>
                            {nftCards}
                        </Grid>
                        <Grid item xs={12}>
                            <Button size='large' fullWidth={true} variant='contained' onClick={mintAgain}
                                    color="primary"
                                    startIcon={<ReplayIcon/>}>
                                Mint Again
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </>;
        }
    }

    return (
        <Dialog
            open={props.isModalOpen}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            maxWidth={false}
        >
            <div className={classes.dialog + " " + (modalState === ModalState.MintForm ? classes.mintFormWidth: classes.showResultsWidth)}>
                {getDialogContent()}
            </div>
        </Dialog>
    );
}
