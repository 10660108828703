import {Typography} from "@material-ui/core";
import React from "react";

interface IProps {
    title: string
}

export default function SectionHeader(props: IProps) {
    return (
        <div className='bounding-box section-header'>
            <img className='waves' src='waves/dark-green.svg' alt='dark green waves background' />
            <div className='section-header-container'>
                <div className='black-background'>
                    <div className='inner-border dk-green'>
                        <Typography align='center' variant='h4'>
                            {props.title}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}